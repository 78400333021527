import React, {useState} from 'react';
import logo from './logo.png';
import './App.css';
import {SocialIcon} from 'react-social-icons';
import Div100vh from 'react-div-100vh';
import FullScreenDialog from "./components/FullScreenDialog";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from '@material-ui/styles';
import Datenschutz from "./components/Datenschutz";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000'
        },
    },
});

function App() {
    const [isDatenschutzOpen, setDatenschutzOpen] = useState(false);
    const [isImpressumOpen, setImpressumOpen] = useState(false);


    return (
        <ThemeProvider theme={theme}>
            <Div100vh className="main" style={{
                width: '100%',
                height: '100rvh'
            }}>
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    ...under construction
                </p>
                <div className="App-nav">
                    <SocialIcon url="https://fb.me/echotronic.band" target="_blank" network="facebook"
                                className="social-icon"/>

                    <SocialIcon url="mailto:band@echotronic.de" target="_blank" network="email"
                                className="social-icon"/>
                </div>
            </Div100vh>

            <div className="footer">
                <Button onClick={() => setDatenschutzOpen(true)}>
                    Datenschutz
                </Button>
                <Button onClick={() => setImpressumOpen(true)}>
                    Impressum
                </Button>
            </div>
            <FullScreenDialog open={isDatenschutzOpen} onClose={() => setDatenschutzOpen(false)}
                              title="Datenschutz">
                <Container fixed>
                    <Datenschutz/>
                </Container>
            </FullScreenDialog>
            <FullScreenDialog open={isImpressumOpen} onClose={() => setImpressumOpen(false)} title="Impressum">
                <Container fixed>
                    <h2>
                        Impressum
                    </h2>
                    <address>
                        Tobias Hegemann<br/>
                        Bachemer Str. 50<br/>
                        50931 Köln
                    </address>
                    <br/>
                    <address>
                        Telefon: 01606924992<br/>
                        E-Mail: band@echotronic.de
                    </address>
                </Container>
            </FullScreenDialog>
        </ThemeProvider>
    );
}

export default App;
